import React from 'react'

import moment from 'moment'
import { DefaultRecord } from 'types/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  baseComplexColumnHeader,
  getPrefixedColumnsHelpers,
  PrefixedComplexColumnsParams,
} from 'components/alix-front/legacy-smart-grid/columns/utils'
import SmartGridIconFlag from 'components/alix-front/legacy-smart-grid/SmartGridIconFlag'
import { SmartGridColumnType } from 'components/alix-front/smart-grid/columns/utils'
import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

import { dateToDisplay } from 'utils/dateParser'
import { getDimensionIcon } from 'utils/iconMapper'
import {
  parseNumber,
  parseCurrency,
  parsePercentage,
} from 'utils/numberParser'
import { convertFromBase } from 'utils/unitConverter'

import {
  getBaseAvailableMeasure,
  getBaseNetFlow,
} from 'reducers/inventories/inventoriesSlice'

import DateColumn from './DateColumn'

export function getPrefixedComplexColumns<T extends DefaultRecord = DefaultRecord>(
  params: PrefixedComplexColumnsParams = {},
) {
  const { prefixColumnDict, prefixComplexColumns, prefixField } =
    getPrefixedColumnsHelpers<T>({
      formatHeader: baseComplexColumnHeader('inventories:smartGridHeaderWithPrefix'),
      ...params,
    })

  const parseExcelDate = (date, includeHours = false) =>
    Date.parse(date) ?
      dateToDisplay(
        moment(date),
        includeHours ? { format: 'DD/MM/YYYY HH:mm' } : { format: 'DD/MM/YYYY' },
      ) :
      ''

  const toggleColumn = (field, t = undefined) => {
    const simple = {
      field,
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
    }

    if (typeof t != 'function') {
      return simple
    }

    return {
      ...simple,
      header: t([
        `inventories:inventory.fields.${field}.label`,
        `inventories:inventory.fields.${field}`,
      ]),
      filterElement: {
        ...simple.filterElement,
        options: [
          { label: t('common:activated'), value: 'true' },
          { label: t('common:deactivated'), value: 'false' },
        ],
      },
      parseValue: (props) =>
        t(`common:${props[field] ? 'activated' : 'deactivated'}`),
    }
  }

  const getReceptionDateColumn = (t = undefined, hideHours = false, dateColumnTemplate = undefined) => {
    const simple = { field: 'receivedDate', sortable: true, filter: true }

    if (typeof t != 'function') {
      return simple
    }

    return {
      ...simple,
      header: t('inventories:inventory.fields.receivedDate.label'),
      template: (props) =>
        dateColumnTemplate(
          hideHours ?
            props[prefixField('receivedDate')] :
            props[prefixField('receivedDatetime')],
          hideHours,
        ),
      parseExcel: (props) =>
        parseExcelDate(
          hideHours ?
            props[prefixField('receivedDate')] :
            props[prefixField('receivedDatetime')],
          !hideHours,
        ),
    }
  }

  const columnDict = {
    item: {
      field: 'item',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      isColumnToggle: false,
    },
    dimensionOrder: {
      field: 'dimensionOrder',
      sortable: true,
      filter: true,
      filterField: 'dimension',
      filterElement: { type: 'multiselect' },
    },
    status: {
      field: 'status',
      isColumnToggle: false,
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
    },
    tag: { field: 'tag', sortable: true, filter: true, isGlobalSearch: true },
    sku: { field: 'sku', sortable: true, filter: true, isGlobalSearch: true },
    upc: { field: 'upc', sortable: true, filter: true, isGlobalSearch: true },
    ean: { field: 'ean', sortable: true, filter: true, isGlobalSearch: true },
    material: {
      field: 'material',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    category: {
      field: 'category',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    serialNumber: {
      field: 'serialNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    description: {
      field: 'description',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    secondaryDescription: {
      field: 'secondaryDescription',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    rawMetric: {
      field: 'rawMetric',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    rawImperial: {
      field: 'rawImperial',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    partNumber: {
      field: 'partNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    treatment: {
      field: 'treatment',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    revision: {
      field: 'revision',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    project: {
      field: 'project',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    plantName: {
      field: 'plantName',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    plannedUnitCost: {
      field: 'plannedUnitCost',
      sortable: true,
      className: 'a-right',
    },
    realUnitCost: {
      field: 'realUnitCost',
      sortable: true,
      className: 'a-right',
    },
    initialMeasure: {
      field: 'initialMeasure',
      sortable: true,
      className: 'a-right',
    },
    availableMeasure: {
      field: 'availableMeasure',
      sortable: true,
      className: 'a-right',
    },
    convertedOnOrderMeasure: {
      field: 'convertedOnOrderMeasure',
      sortable: true,
      className: 'a-right',
    },
    convertedReservedMeasure: {
      field: 'convertedReservedMeasure',
      sortable: true,
      className: 'a-right',
    },
    convertedNetFlowMeasure: {
      field: 'convertedNetFlowMeasure',
      sortable: true,
      className: 'a-right',
    },
    convertedCurrentMeasure: {
      field: 'convertedCurrentMeasure',
      sortable: true,
      className: 'a-right',
    },
    convertedGrossWeight: {
      field: 'convertedGrossWeight',
      sortable: true,
      className: 'a-right',
    },
    convertedTareWeight: {
      field: 'convertedTareWeight',
      sortable: true,
      className: 'a-right',
    },
    convertedNetWeight: {
      field: 'convertedNetWeight',
      sortable: true,
      className: 'a-right',
    },
    measureUnit: {
      field: 'measureUnit',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    convertedUnitWeight: {
      field: 'convertedUnitWeight',
      sortable: true,
      className: 'a-right',
    },
    expirationDate: { field: 'expirationDate', sortable: true, filter: true },
    invoiceDate: { field: 'invoiceDate', sortable: true, filter: true },
    invoice: {
      field: 'invoice',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    receivedDate: getReceptionDateColumn(),
    receptionNumber: {
      field: 'receptionNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    receptionCarrier: {
      field: 'receptionCarrier',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    plannedTargetDisplayName: {
      field: 'plannedTargetDisplayName',
      sortable: true,
      filter: true,
    },
    manufacturer: {
      field: 'manufacturer',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    location: {
      field: 'location',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    vendorName: {
      field: 'vendorName',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    vendorPartNumber: {
      field: 'vendorPartNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    convertedVendorListPrice: {
      field: 'convertedVendorListPrice',
      sortable: true,
    },
    vendorDiscount: { field: 'vendorDiscount', sortable: true },
    vendorCurrencySymbol: { field: 'vendorCurrencySymbol', sortable: true },
    vendorExchangeRate: { field: 'vendorExchangeRate', sortable: true },
    baseCost: { field: 'baseCost', sortable: true, className: 'a-right' },
    createdDate: { field: 'createdDate', sortable: true, filter: true },
    createdBy: {
      field: 'createdBy',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    modifiedDate: { field: 'modifiedDate', sortable: true, filter: true },
    modifiedBy: {
      field: 'modifiedBy',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    manufacturedDate: {
      field: 'manufacturedDate',
      sortable: true,
      filter: true,
    },
    expectedDeliveryDate: {
      field: 'expectedDeliveryDate',
      sortable: true,
      filter: true,
    },
    returnNumber: {
      field: 'returnNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    returnDate: { field: 'returnDate', sortable: true, filter: true },
    isDropship: toggleColumn('isDropship'),
    groupName: {
      field: 'groupName',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    type: {
      field: 'type',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      filterElement: { type: 'multiselect' },
    },
    inventoryManagementType: {
      field: 'inventoryManagementType',
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
    },
    isSelling: toggleColumn('isSelling'),
    isManufactured: toggleColumn('isManufactured'),
    isPurchased: toggleColumn('isPurchased'),
    isBin: toggleColumn('isBin'),
    referenceNumber: {
      field: 'referenceNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    purchaseOrderItemContractNumber: {
      field: 'purchaseOrderItemContractNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
  }

  const prefixedColumnDict = prefixColumnDict(columnDict)

  function getComplexColumns(
    t,
    {
      primaryLanguage,
      secondaryLanguage,
      culture,
      measureDigits,
      currencyCode,
      currencySymbol,
      dateColumnTemplate,
      simpleDict,
      hideHours,
      dataSetName,
      itemFullcardRef,
      options,
      insertedIds,
    },
  ) {
    const inventoryManagementTypes = {
      quantity_and_value: t(
        'items:item.fields.inventoryManagementType.quantityAndValue',
      ),
      quantity_only: t(
        'items:item.fields.inventoryManagementType.quantityOnly',
      ),
      not_managed: t('items:item.fields.inventoryManagementType.notManaged'),
    }

    const specificDataSetColumns = _getComplexColumnsByDataSetName(
      dataSetName,
      options,
    )

    const complexColumns = {
      item: {
        header: t('inventories:inventory.fields.item.label'),
        hidden: false,
        template: (props) => {
          const iconData = getDimensionIcon(props[prefixField('dimension')])
          return (
            <div className="a-flex">
              <div className="a-inventory-grid-dimension-icon">
                <FontAwesomeIcon
                  icon={['fas', iconData.icon]}
                  transform={iconData.transform}
                />
              </div>
              <div
                className={`a-smart-grid-default-template ${
                  !!itemFullcardRef ? 'a-clickable-link' : ''
                }`}
                title={props[prefixField('item')]}
                onClick={() =>
                  !!itemFullcardRef ?
                    itemFullcardRef.current?.open(
                      'item',
                      props[prefixField('templateId')],
                    ) :
                    null}
              >
                {props[prefixField('item')]}
              </div>
            </div>
          )
        },
      },
      dimensionOrder: {
        header: t('inventories:inventory.fields.dimensionOrder'),
        hidden: false,
        filterElement: {
          ...simpleDict[prefixField('dimensionOrder')]?.filterElement,
          options: [
            {
              label: t('common:dimension.qty'),
              value: 'qty',
              dimension: 'qty',
            },
            {
              label: t('common:dimension.weight'),
              value: 'weight',
              dimension: 'weight',
            },
            {
              label: t('common:dimension.length'),
              value: 'length',
              dimension: 'length',
            },
            {
              label: t('common:dimension.surface'),
              value: 'surface',
              dimension: 'surface',
            },
            {
              label: t('common:dimension.volume'),
              value: 'volume',
              dimension: 'volume',
            },
          ],
          itemTemplate: (option) => {
            const iconData = getDimensionIcon(option.dimension)
            return (
              <div className="a-flex">
                <div className="a-inventory-grid-dimension-icon">
                  <FontAwesomeIcon
                    icon={['fas', iconData.icon]}
                    transform={iconData.transform}
                  />
                </div>
                <div>
                  {option.label}
                </div>
              </div>
            )
          },
        },
        template: (props) => {
          if (!props[prefixField('dimension')]) {
            return null
          }

          const iconData = getDimensionIcon(props[prefixField('dimension')])
          return (
            <div className="a-flex">
              <div className="a-inventory-grid-dimension-icon">
                <FontAwesomeIcon
                  icon={['fas', iconData.icon]}
                  transform={iconData.transform}
                />
              </div>
              <div
                className="a-smart-grid-default-template"
                title={t(`common:dimension.${props[prefixField('dimension')]}`)}
              >
                {t(`common:dimension.${props[prefixField('dimension')]}`)}
              </div>
            </div>
          )
        },
        parseExcel: (props) =>
          t(`common:dimension.${props[prefixField('dimension')]}`),
      },
      status: {
        header: t('inventories:inventory.fields.status.label'),
        hidden: false,
        filterElement: {
          ...simpleDict[prefixField('status')]?.filterElement,
          options: [
            { label: t('inventories:status.ready'), value: 'ready' },
            { label: t('inventories:status.stand-by'), value: 'stand-by' },
            { label: t('inventories:status.to-order'), value: 'to-order' },
            { label: t('inventories:status.ordered'), value: 'ordered' },
            { label: t('inventories:status.done'), value: 'done' },
            { label: t('inventories:status.merged'), value: 'merged' },
            { label: t('inventories:status.returned'), value: 'returned' },
            { label: t('inventories:status.to-return'), value: 'to-return' },
          ],
          itemTemplate: (option) => {
            return (
              <SmartStatus
                status={option.value}
                dictionaryKey="inventory"
              />
            )
          },
        },
        template: (props) => {
          if (!props[prefixField('status')]) {
            return null
          }

          return (
            <SmartStatus
              status={props[prefixField('status')]}
              dictionaryKey="inventory"
            />
          )
        },
        parseExcel: (props) => {
          const statusDict = {
            ready: t('inventories:status.ready'),
            stand_by: t('inventories:status.stand-by'),
            to_order: t('inventories:status.to-order'),
            ordered: t('inventories:status.ordered'),
            done: t('inventories:status.done'),
            merged: t('inventories:status.merged'),
            returned: t('inventories:status.returned'),
            to_return: t('inventories:status.to-return'),
          }
          return statusDict[props[prefixField('status')]]
        },
      },
      tag: {
        header: t('inventories:inventory.fields.tag.label'),
        hidden: false,
        template: !insertedIds?.length ?
          undefined :
          (props) => {
            const id = props[prefixField('id')]
            const isVisibleInsertFlag = insertedIds.includes(id)

            return (
              <SmartGridIconFlag
                isVisible={isVisibleInsertFlag}
                title={t('inventories:tooltips.alreadyInserted')}
                icon={['fas', 'lock']}
                id={id}
              >
                {props[prefixField('tag')]}
              </SmartGridIconFlag>
            )
          },
      },
      sku: { header: t('inventories:inventory.fields.sku') },
      upc: { header: t('inventories:inventory.fields.upc') },
      ean: { header: t('inventories:inventory.fields.ean') },
      material: {
        header: t('inventories:inventory.fields.material'),
        hidden: false,
      },
      category: { header: t('inventories:inventory.fields.category') },
      serialNumber: { header: t('inventories:inventory.fields.serialNumber') },
      description: {
        header: `${t('inventories:inventory.fields.description.label')}${
          primaryLanguage ? ` (${primaryLanguage})` : ''
        }`,
        hidden: false,
      },
      secondaryDescription: {
        header: `${t('inventories:inventory.fields.description.label')}${
          secondaryLanguage ? ` (${secondaryLanguage})` : ''
        }`,
      },
      rawMetric: { header: t('inventories:inventory.fields.rawMetric') },
      rawImperial: { header: t('inventories:inventory.fields.rawImperial') },
      partNumber: {
        header: t('inventories:inventory.fields.partNumber'),
        hidden: false,
      },
      treatment: { header: t('inventories:inventory.fields.treatment') },
      revision: { header: t('inventories:inventory.fields.revision') },
      project: {
        header: t('inventories:inventory.fields.project'),
        hidden: false,
      },
      plantName: { header: t('inventories:inventory.fields.plant') },
      plannedUnitCost: {
        header: t('inventories:inventory.fields.plannedUnitCost'),
        parseValue: (props) => {
          return `${parseCurrency(
            props[prefixField('plannedUnitCost')],
            props[prefixField('vendorCurrencyCode')] ||
              currencyCode ||
              undefined,
            props[prefixField('vendorCurrencySymbol')] ||
              currencySymbol ||
              undefined,
            4,
            culture,
          )} / ${props[prefixField('measureUnit')]}`
        },
        parseExcel: (props) => props[prefixField('plannedUnitCost')],
      },
      realUnitCost: {
        header: t('inventories:inventory.fields.realUnitCost.label'),
        parseValue: (props) => {
          return `${parseCurrency(
            props[prefixField('realUnitCost')],
            props[prefixField('vendorCurrencyCode')] ||
              currencyCode ||
              undefined,
            props[prefixField('vendorCurrencySymbol')] ||
              currencySymbol ||
              undefined,
            4,
            culture,
          )} / ${props[prefixField('measureUnit')]}`
        },
        parseExcel: (props) => props[prefixField('realUnitCost')],
      },
      initialMeasure: {
        header: t('inventories:inventory.fields.initialMeasure'),
        parseValue: (props) => {
          return `${parseNumber(
            convertFromBase(
              props[prefixField('dimension')],
              props[prefixField('initialMeasure')],
              props[prefixField('measureUnit')],
              false,
            ),
            measureDigits,
            culture,
          )} ${props[prefixField('measureUnit')]}`
        },
        parseExcel: (props) => props[prefixField('convertedInitialMeasure')],
      },
      availableMeasure: {
        header: t('inventories:inventory.fields.availableMeasure'),
        parseValue: (props) => {
          return `${parseNumber(
            convertFromBase(
              props[prefixField('dimension')],
              getBaseAvailableMeasure(props),
              props[prefixField('measureUnit')],
              false,
            ),
            measureDigits,
            culture,
          )} ${props[prefixField('measureUnit')]}`
        },
        parseExcel: (props) => props[prefixField('availableMeasure')],
      },
      convertedCurrentMeasure: {
        header: t('inventories:inventory.fields.currentMeasure'),
        parseValue: (props) => {
          return `${parseNumber(
            convertFromBase(
              props[prefixField('dimension')],
              props[prefixField('currentMeasure')],
              props[prefixField('measureUnit')],
              false,
            ),
            measureDigits,
            culture,
          )} ${props[prefixField('measureUnit')]}`
        },
        parseExcel: (props) => props[prefixField('convertedCurrentMeasure')],
      },
      convertedOnOrderMeasure: {
        header: t('inventories:inventory.fields.onOrderMeasure'),
        parseValue: (props) => {
          return `${parseNumber(
            convertFromBase(
              props[prefixField('dimension')],
              props[prefixField('onOrderMeasure')],
              props[prefixField('measureUnit')],
              false,
            ),
            measureDigits,
            culture,
          )} ${props[prefixField('measureUnit')]}`
        },
        parseExcel: (props) => props[prefixField('convertedOnOrderMeasure')],
      },
      convertedReservedMeasure: {
        header: t('inventories:inventory.fields.reservedMeasure'),
        parseValue: (props) => {
          return `${parseNumber(
            convertFromBase(
              props[prefixField('dimension')],
              props[prefixField('reservedMeasure')],
              props[prefixField('measureUnit')],
              false,
            ),
            measureDigits,
            culture,
          )} ${props[prefixField('measureUnit')]}`
        },
        parseExcel: (props) => props[prefixField('convertedReservedMeasure')],
      },
      convertedNetFlowMeasure: {
        header: t('inventories:inventory.fields.netFlowMeasure'),
        parseValue: (props) => {
          return `${parseNumber(
            convertFromBase(
              props[prefixField('dimension')],
              getBaseNetFlow(props),
              props[prefixField('measureUnit')],
              false,
            ),
            measureDigits,
            culture,
          )} ${props[prefixField('measureUnit')]}`
        },
        parseExcel: (props) => props[prefixField('convertedNetFlowMeasure')],
      },
      convertedGrossWeight: {
        header: t('inventories:inventory.fields.grossWeight'),
        parseValue: (props) => {
          return `${parseNumber(
            props[prefixField('convertedGrossWeight')],
            measureDigits,
            culture,
          )} ${props[prefixField('weightUnit')]}`
        },
        parseExcel: (props) => props[prefixField('convertedGrossWeight')],
      },
      convertedTareWeight: {
        header: t('inventories:inventory.fields.tareWeight'),
        parseValue: (props) => {
          return `${parseNumber(
            props[prefixField('convertedTareWeight')],
            measureDigits,
            culture,
          )} ${props[prefixField('weightUnit')]}`
        },
        parseExcel: (props) => props[prefixField('convertedTareWeight')],
      },
      convertedNetWeight: {
        header: t('inventories:inventory.fields.netWeight'),
        parseValue: (props) => {
          return `${parseNumber(
            props[prefixField('convertedNetWeight')],
            measureDigits,
            culture,
          )} ${props[prefixField('weightUnit')]}`
        },
        parseExcel: (props) => props[prefixField('convertedNetWeight')],
      },
      measureUnit: { header: t('inventories:inventory.fields.measureUnit') },
      convertedUnitWeight: {
        header: t('inventories:inventory.fields.unitWeight'),
        parseValue: (props) => {
          if (props[prefixField('dimension')] != 'weight') {
            return `${parseNumber(
              props[prefixField('convertedUnitWeight')],
              measureDigits,
              culture,
            )} ${props[prefixField('weightUnit')]} / ${
              props[prefixField('measureUnit')]
            }`
          }
        },
        parseExcel: (props) => {
          if (props[prefixField('dimension')] != 'weight') {
            return props[prefixField('convertedUnitWeight')]
          }
        },
      },
      expirationDate: {
        header: t('inventories:inventory.fields.expirationDate'),
        template: (props) => {
          const className =
            new Date(props[prefixField('expirationDate')]).setHours(
              0,
              0,
              0,
              0,
            ) < new Date().setHours(0, 0, 0, 0) ?
              'a-red' :
              ''
          return (
            <div className={className}>
              <DateColumn
                date={props[prefixField('expirationDate')]}
                hideHours
              />
            </div>
          )
        },
        parseExcel: (props) =>
          parseExcelDate(props[prefixField('expirationDate')]),
      },
      invoiceDate: {
        header: t('inventories:inventory.fields.invoiceDate.label'),
        template: (props) =>
          dateColumnTemplate(props[prefixField('invoiceDate')], true),
        parseExcel: (props) =>
          parseExcelDate(props[prefixField('invoiceDate')]),
      },
      invoice: { header: t('inventories:inventory.fields.invoice.label') },
      receivedDate: getReceptionDateColumn(t, hideHours, dateColumnTemplate),
      receptionNumber: {
        header: t('inventories:inventory.fields.receptionNumber.label'),
      },
      receptionCarrier: {
        header: t('inventories:inventory.fields.receptionCarrier.label'),
      },
      plannedTargetDisplayName: {
        header: t('inventories:inventory.fields.plannedTarget'),
      },
      manufacturer: { header: t('inventories:inventory.fields.manufacturer') },
      location: {
        header: t('inventories:inventory.fields.location'),
      },
      vendorName: { header: t('inventories:inventory.fields.vendor') },
      vendorPartNumber: {
        header: t('inventories:inventory.fields.vendorPartNumber.label'),
      },
      convertedVendorListPrice: {
        header: t('inventories:inventory.fields.vendorListPrice.label'),
        parseValue: (props) => {
          return `${parseCurrency(
            props[prefixField('convertedVendorListPrice')],
            props[prefixField('vendorCurrencyCode')] ||
              currencyCode ||
              undefined,
            props[prefixField('vendorCurrencySymbol')] ||
              currencySymbol ||
              undefined,
            4,
            culture,
          )}`
        },
        parseExcel: (props) => props[prefixField('convertedVendorListPrice')],
      },
      vendorDiscount: {
        header: t('inventories:inventory.fields.vendorDiscount.label'),
        parseValue: (props) => {
          return `${parsePercentage(
            props[prefixField('vendorDiscount')] / 100,
            2,
            culture,
          )}`
        },
      },
      vendorCurrencySymbol: {
        header: t('inventories:inventory.fields.vendorCurrency'),
      },
      vendorExchangeRate: {
        header: t('inventories:inventory.fields.vendorExchangeRate.label'),
        parseValue: (props) => {
          return `${parseNumber(
            props[prefixField('vendorExchangeRate')],
            { minimumFractionDigits: 2, maximumFractionDigits: 8 },
            culture,
          )}`
        },
        parseExcel: (props) => props[prefixField('vendorExchangeRate')],
      },
      baseCost: {
        header: t('inventories:inventory.fields.vendorBaseCost'),
        parseValue: (props) => {
          return `${parseCurrency(
            props[prefixField('baseCost')],
            props[prefixField('vendorCurrencyCode')] ||
              currencyCode ||
              undefined,
            props[prefixField('vendorCurrencySymbol')] ||
              currencySymbol ||
              undefined,
            4,
            culture,
          )}`
        },
        parseExcel: (props) => props[prefixField('baseCost')],
      },
      createdDate: {
        header: t('inventories:inventory.fields.createdDate'),
        template: (props) =>
          dateColumnTemplate(props[prefixField('createdDate')]),
        parseExcel: (props) =>
          parseExcelDate(props[prefixField('createdDate')]),
      },
      createdBy: { header: t('inventories:inventory.fields.createdBy') },
      modifiedDate: {
        header: t('inventories:inventory.fields.modifiedDate'),
        template: (props) =>
          dateColumnTemplate(props[prefixField('modifiedDate')]),
        parseExcel: (props) =>
          parseExcelDate(props[prefixField('modifiedDate')]),
      },
      modifiedBy: {
        header: t('inventories:inventory.fields.modifiedBy'),
      },
      manufacturedDate: {
        header: t('inventories:inventory.fields.manufacturedDate'),
        template: (props) =>
          dateColumnTemplate(props[prefixField('manufacturedDate')], true),
        parseExcel: (props) =>
          parseExcelDate(props[prefixField('manufacturedDate')]),
      },
      expectedDeliveryDate: {
        header: t('inventories:inventory.fields.deliveryDate'),
        hidden: false,
        template: (props) =>
          dateColumnTemplate(props[prefixField('expectedDeliveryDate')], true),
        parseExcel: (props) =>
          parseExcelDate(props[prefixField('expectedDeliveryDate')]),
      },
      returnNumber: { header: t('inventories:inventory.fields.returnNumber') },
      returnDate: {
        header: t('inventories:inventory.fields.returnDate'),
        template: (props) =>
          dateColumnTemplate(props[prefixField('returnDate')], true),
        parseExcel: (props) => parseExcelDate(props[prefixField('returnDate')]),
      },
      isDropship: toggleColumn('isDropship', t),
      groupName: { header: t('inventories:inventory.fields.group') },
      type: {
        header: t('inventories:inventory.fields.type'),
        filterElement: {
          ...simpleDict[prefixField('type')]?.filterElement,
          options: [
            { label: t('items:item.fields.type.good'), value: 'good' },
            { label: t('items:item.fields.type.service'), value: 'service' },
            {
              label: t('items:item.fields.type.custom_service'),
              value: 'custom_service',
            },
          ],
        },
        parseValue: (props) =>
          t(`items:item.fields.type.${props[prefixField('type')]}`),
      },
      inventoryManagementType: {
        header: t('inventories:inventory.fields.inventoryManagementType'),
        filterElement: {
          ...simpleDict[prefixField('inventoryManagementType')]?.filterElement,
          options: [
            {
              label: inventoryManagementTypes.quantity_and_value,
              value: 'quantity_and_value',
            },
            {
              label: inventoryManagementTypes.quantity_only,
              value: 'quantity_only',
            },
            {
              label: inventoryManagementTypes.not_managed,
              value: 'not_managed',
            },
          ],
        },
        parseValue: (props) =>
          inventoryManagementTypes[
            props[prefixField('inventoryManagementType')]
          ],
      },
      isSelling: toggleColumn('isSelling', t),
      isManufactured: toggleColumn('isManufactured', t),
      isPurchased: toggleColumn('isPurchased', t),
      isBin: toggleColumn('isBin', t),
      referenceNumber: {
        header: t('inventories:inventory.fields.referenceNumber'),
      },
      purchaseOrderItemContractNumber: {
        header: t(
          'inventories:inventory.fields.purchaseOrderItemContractNumber',
        ),
      },
      ...specificDataSetColumns,
    }

    function _getComplexColumnsByDataSetName(dataSetName, options) {
      const columns: Record<string, SmartGridColumnType> = {}

      switch (dataSetName) {
      case 'negative_inventory_view':
        columns.convertedNegativeMeasure = {
          header: t('inventories:inventory.fields.negativeMeasure'),
          parseValue: (props) => {
            return `${parseNumber(
              props[prefixField('convertedNegativeMeasure')],
              measureDigits,
              culture,
            )} ${props[prefixField('measureUnit')]}`
          },
          parseExcel: (props) =>
            props[prefixField('convertedNegativeMeasure')],
          field: 'convertedNegativeMeasure',
          sortable: true,
          className: 'a-right',
        }
        break
      case 'inventory_balance_view':
        columns.convertedLedgerMeasureSum = {
          header: t('inventories:inventory.fields.ledgerMeasureSum'),
          parseValue: (props) => {
            return `${parseNumber(
              props[prefixField('convertedLedgerMeasureSum')],
              measureDigits,
              culture,
            )} ${props[prefixField('measureUnit')]}`
          },
          parseExcel: (props) =>
            props[prefixField('convertedLedgerMeasureSum')],
          field: 'convertedLedgerMeasureSum',
          sortable: true,
          className: 'a-right',
        }
        columns.convertedInventoryMeasureSum = {
          header: t('inventories:inventory.fields.inventoryMeasureSum'),
          parseValue: (props) => {
            return `${parseNumber(
              props[prefixField('convertedInventoryMeasureSum')],
              measureDigits,
              culture,
            )} ${props[prefixField('measureUnit')]}`
          },
          parseExcel: (props) =>
            props[prefixField('convertedInventoryMeasureSum')],
          field: 'convertedInventoryMeasureSum',
          sortable: true,
          className: 'a-right',
        }
        break
      case 'not_consumed_inventory_view':
        columns.convertedPlannedMeasure = {
          header: t('inventories:inventory.fields.plannedMeasure'),
          parseValue: (props) => {
            return `${parseNumber(
              convertFromBase(
                props[prefixField('dimension')],
                props[prefixField('plannedMeasure')],
                props[prefixField('measureUnit')],
                false,
              ),
              measureDigits,
              culture,
            )} ${props[prefixField('measureUnit')]}`
          },
          parseExcel: (props) =>
            props[prefixField('convertedPlannedMeasure')],
          field: 'convertedPlannedMeasure',
          sortable: true,
          className: 'a-right',
        }
        columns.convertedConsumedMeasure = {
          header: t('inventories:inventory.fields.consumedMeasure'),
          parseValue: (props) => {
            return `${parseNumber(
              convertFromBase(
                props[prefixField('dimension')],
                props[prefixField('consumedMeasure')],
                props[prefixField('measureUnit')],
                false,
              ),
              measureDigits,
              culture,
            )} ${props[prefixField('measureUnit')]}`
          },
          parseExcel: (props) =>
            props[prefixField('convertedConsumedMeasure')],
          field: 'convertedConsumedMeasure',
          sortable: true,
          className: 'a-right',
        }
        columns.itemNumber = {
          header: t('inventories:inventory.fields.itemNumber'),
          filter: true,
          sortable: true,
          field: 'itemNumber',
        }
        columns.itemTitle = {
          header: t('inventories:inventory.fields.itemTitle'),
          filter: true,
          sortable: true,
          field: 'itemTitle',
          template: (props, _1, _2, { iframedFullcard }) => (
            <span
              className="a-smart-grid-default-template a-clickable-link"
              title={props[prefixField('itemTitle')]}
              onClick={() =>
                iframedFullcard?.open('card', props[prefixField('itemId')])}
            >
              {props[prefixField('itemTitle')]}
            </span>
          ),
        }
        columns.itemProjectTitle = {
          header: t('inventories:inventory.fields.itemProjectTitle'),
          filter: true,
          sortable: true,
          field: 'itemProjectTitle',
        }
        break
      }
      return columns
    }

    return prefixComplexColumns(complexColumns, prefixedColumnDict, { t })
  }

  return {
    getComplexColumns,
    columnDict: prefixedColumnDict,
    prefixField,
  }
}

const { columnDict, getComplexColumns } = getPrefixedComplexColumns()

export { columnDict, getComplexColumns }

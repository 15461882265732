
import { hash } from 'components/alix-front/smart-grid/columns/utils'

import { parse, buildGetUrl } from 'utils/api'
import { joinOnlyStrings } from 'utils/getFirstOfType'
import { safeFetch, safeFetchJson } from 'utils/safeFetch'
import { convertFromBase } from 'utils/unitConverter'

import { planningTypes as types } from './types'

const dataSetName = 'planningView'

export const getFields = () => {
  return {
    'available': {
      dataSetName, dbField: 'available', dataSetAlias: 'calculated', type: 'measure',
      parse: convertMeasureCb('calculated_available'),
    },
    'netFlow': {
      dataSetName, dbField: 'net_flow', dataSetAlias: 'calculated', type: 'measure',
      parse: convertMeasureCb('calculated_net_flow'),
    },
    'demand': {
      dataSetName, dbField: 'demand', dataSetAlias: 'calculated', type: 'measure',
      parse: convertMeasureCb('calculated_demand'),
    },
    'onHand': {
      dataSetName, dbField: 'on_hand', dataSetAlias: 'calculated', type: 'measure',
      parse: convertMeasureCb('calculated_on_hand'),
    },
    'onOrder': {
      dataSetName, dbField: 'on_order', dataSetAlias: 'calculated', type: 'measure',
      parse: convertMeasureCb('calculated_on_order'),
    },
    'position': { dataSetName, dbField: 'position', dataSetAlias: 'calculated', type: 'string' },
    'baseRecommendation': { dataSetName, dbField: 'recommendation', dataSetAlias: 'calculated', type: 'measure' },
    'recommendation': {
      dataSetName, dbField: 'recommendation', dataSetAlias: 'calculated', type: 'measure',
      parse: convertMeasureCb('calculated_recommendation'),
    },
    'cid': { dataSetName, dbField: 'cid', type: 'id' },
    'dimension': { dataSetName, dbField: 'dimension', type: 'string' },
    'max': {
      dataSetName, dbField: 'maximum_stock_level', type: 'measure',
      parse: convertMeasureCb('maximum_stock_level'),
    },
    'plantId': { dataSetName, dbField: 'plant_id', type: 'id', relationEntity: 'plants' },
    'sku': { dataSetName, dbField: 'sku', type: 'string' },
    'manufacturingOrderId': { dataSetName, dbField: 'manufacturing_order_id', type: 'id' },
    'templateTitle': { dataSetName, dbField: 'template_title', type: 'string' },
    'description': { dataSetName, dbField: 'template_description', type: 'string' },
    'secondaryDescription': { dataSetName, dbField: 'template_secondary_description', type: 'string' },
    'longDescriptionPurchase': { dataSetName, dbField: 'template_long_description_purchase', type: 'string' },
    'longSecondaryDescriptionPurchase': {
      dataSetName,
      dbField: 'template_long_secondary_description_purchase',
      type: 'string',
    },
    'initialMeasure': { dataSetName, dbField: 'template_initial_measure', type: 'float' },
    'templateId': { dataSetName, dbField: 'template_id', type: 'id' },
    'templatePlanningId': { dataSetName, dbField: 'template_planning_id', type: 'id' },
    'templatePlanningPlantId': { dataSetName, dbField: 'template_planning_plant_id', type: 'id' },
    'templatePlanningType': { dataSetName, dbField: 'template_planning_type', type: 'string' },
    'topOfGreen': {
      dataSetName, dbField: 'top_of_green', type: 'integer',
      parse: convertMeasureCb('top_of_green'),
    },
    'topOfRed': {
      dataSetName, dbField: 'top_of_red', type: 'integer',
      parse: convertMeasureCb('top_of_red'),
    },
    'topOfYellow': {
      dataSetName, dbField: 'top_of_yellow', type: 'integer',
      parse: convertMeasureCb('top_of_yellow'),
    },
    'unit': { dataSetName, dbField: 'unit', type: 'string' },
    'id': { dataSetName, dbField: 'template_id', type: 'id' },
    'dimension': { dataSetName, dbField: 'dimension', type: 'string' },
    'partNumber': { dataSetName, dbField: 'template_part_number', type: 'string' },
    'measureUnit': { dataSetName, dbField: 'unit', type: 'string' },
    'categoryTitle': { dataSetName, dbField: 'template_category_title', type: 'string' },
    'materialTitle': { dataSetName, dbField: 'template_material_title', type: 'string' },
    'treatmentTitle': {
      dataSetName,
      dbField: 'template_treatment_title',
    },
    'itemGroup': { dataSetName, dbField: 'template_group_name', type: 'string' },
    'itemType': {
      dataSetName,
      dbField: 'template_type',
      type: 'enum',
      translationPath: 'items:item.fields.type',
      values: ['good', 'service', 'custom_service'],
      defaultValue: 'good',
      parse: (props) => props['template_type'] ?? 'good',
    },
    'inventoryManagementType': {
      dataSetName,
      dbField: 'template_inventory_management_type',
      type: 'enum',
      translationPath: 'items:item.fields.inventoryManagementType',
      values: ['quantity_and_value', 'quantity_only'],
      parse: (props) => props['template_inventory_management_type'] ?? 'quantity_and_value',
    },
    'isManufactured': { dataSetName, dbField: 'template_is_manufactured', type: 'boolean', defaultValue: false },
    'isPurchased': { dataSetName, dbField: 'template_is_purchased', type: 'boolean', defaultValue: false },
    'isSelling': { dataSetName, dbField: 'template_is_selling', type: 'boolean', defaultValue: false },
    'status': {
      dataSetName,
      dbField: 'template_status',
      type: 'status',
      isEdit: true,
      values: ['active', 'inactive'],
      defaultValue: 'active',
      parse: (props) => props['template_status'] ?? 'active',
    },
    'mainSupplierDisplayName': { dataSetName, dbField: 'main_supplier_display_name', type: 'string' },
  }
}

export const getDetailFields = () => {
  return {
    'id': {
      parse: (props) => hash(
        Object.keys(props).sort().map((k) => `${k}:${String(props[k])}`).join(';'),
      ),
    },
    'date': { dataSetName, dbField: 'planned_date', type: 'date' },
    'type': {
      dataSetName,
      dbField: 'planned_type',
      type: 'enum',
      translationPath: 'planning:detailsGrid.typeEnum',
      values: ['on_hand', 'sales', 'production', 'consumption', 'reception'],
      parse: 'planned_type' ?? 'on_hand',
    },
    'description': { dataSetName, dbField: 'template_description', type: 'string' },
    'quantity': {
      dataSetName, dbField: 'measure', type: 'measure',
      parse: convertDetailsQuantity,
    },
    'netFlow': {
      dataSetName, dbField: 'cumulative', type: 'measure',
      parse: convertMeasureCb('cumulative'),
    },
    'position': { dataSetName, dbField: 'position', type: 'string' },
    'dimension': { dataSetName, dbField: 'dimension', type: 'string' },
    'unit': { dataSetName, dbField: 'unit', type: 'string' },
    'sku': { dataSetName, dbField: 'sku', type: 'string' },
    'planningType': { dataSetName, dbField: 'planning_type', type: 'string' },
    'reference': {
      parse: (props) => {
        const type = props.planned_type?.toUpperCase()

        switch (type) {
        case 'ON_HAND': {
          return ''
        }
        case 'SALES': {
          return joinOnlyStrings([props.sales_order_number, props.contact_display_name], ' | ')
        }
        case 'PRODUCTION':
        case 'CONSUMPTION': {
          return joinOnlyStrings([props.item_detailed_number, props.item_title], ' | ')
        }
        case 'RECEPTION': {
          return joinOnlyStrings([props.invoice_title, props.supplier_display_name], ' | ')
        }
        }
      },
    },
    'referenceId': {
      parse: (props) => {
        const type = props.planned_type?.toUpperCase()

        switch (type) {
        case 'ON_HAND': {
          return ''
        }
        case 'SALES': {
          return props.sales_order_id
        }
        case 'PRODUCTION':
        case 'CONSUMPTION': {
          return props.item_id
        }
        case 'RECEPTION': {
          if (props.sales_order_id) {
            return props.sales_order_id
          }
          return props.purchase_order_id
        }
        }
      },
    },

    'referenceType': {
      parse: (props) => {
        const type = props.planned_type?.toUpperCase()

        switch (type) {
        case 'ON_HAND': {
          return 'inventory'
        }
        case 'SALES': {
          return 'sales_orders'
        }
        case 'PRODUCTION':
        case 'CONSUMPTION': {
          return 'cards'
        }
        case 'RECEPTION': {
          if (props.sales_order_id) {
            return 'sales_orders'
          }
          return 'purchase_orders'
        }
        }
      },
    },
    'templateTitle': { dataSetName, dbField: 'template_title', type: 'string' },
  }
}

const convertDetailsQuantity = (data, options = {}) => {
  const isNegative = data.planning_type === 'qualified_demand'
  const quantity = convertFromBase(data.dimension, data.measure, data.unit || options.defaultUnits)

  return isNegative && quantity > 0 ? quantity * -1 : quantity
}

const convertMeasureCb = (field) => (data, options = {}) => {
  return convertFromBase(data.dimension, data[field], data.unit || options.defaultUnits, true)
}

const parsePlanning = (planning, mapData = {}) => {
  const entityFields = getFields()

  const option = {
    fields: entityFields,
    dataSetName: dataSetName,
    defaultData: parse({}, { fields: entityFields }),
    defaultUnits: mapData.defaultUnits,
  }

  return parse(planning, option)
}

const parsePlanningDetail = (detail, mapData = {}) => {
  const entityFields = getDetailFields()

  const option = {
    fields: entityFields,
    dataSetName,
    defaultData: parse({}, { fields: entityFields }),
    defaultUnits: mapData.defaultUnits,
  }

  return parse(detail, option)
}

const initState = {
  planningData: [],
  planningCount: 0,
  detailsData: [],
  detailsCount: 0,
  detailsData: [],
  fields: getFields(),
  detailsFields: getDetailFields(),
}

export default function planningReducer(state = initState, action) {
  const { payload = [] } = action

  switch (action.type) {
  case types.GET_PLANNING: {
    return {
      ...state,
      planningData: payload,
    }
  }
  case types.GET_PLANNING_DETAILS: {
    return {
      ...state,
      detailsData: payload,
    }
  }
  case types.GET_PLANNING_COUNT: {
    return {
      ...state,
      planningCount: payload,
    }
  }
  case types.GET_PLANNING_DETAILS_COUNT: {
    return {
      ...state,
      detailsCount: payload,
    }
  }
  default:
    return state
  }
}

export function fetchPlanningData(data, mapData) {
  return async function fetchPlanningThunk(dispatch) {
    let { isSuccess, result } = await safeFetchJson(buildGetUrl('/new_api/inventories/templates/planning', data))

    if (!isSuccess) {
      dispatch({ type: types.GET_PLANNING, error: result })

      return []
    } else {
      result = result.map((r) => parsePlanning(r))

      dispatch({ type: types.GET_PLANNING, payload: result })

      return result
    }
  }
}

export function fetchPlanningDetailData(data, mapData) {
  return async function fetchPlanningDetailThunk(dispatch) {
    let { isSuccess, result } = await safeFetchJson(
      buildGetUrl('/new_api/inventories/templates/planning/details', data),
    )

    if (!isSuccess) {
      dispatch({ type: types.GET_PLANNING_DETAILS, error: result })
    } else {
      result = result.map((r) => parsePlanningDetail(r))

      dispatch({ type: types.GET_PLANNING_DETAILS, payload: result })
    }

    return result
  }
}

export function fetchPlanningDetailDataCount(data) {
  return async function fetchPlanningThunk(dispatch) {
    const { isSuccess, result } = await safeFetchJson(
      buildGetUrl('/new_api/inventories/templates/planning/details/count', data),
    )

    let count = 0
    if (!isSuccess) {
      dispatch({ type: types.GET_PLANNING_DETAILS_COUNT, error: result })
    } else {
      count = +result[0].count || 0
      dispatch({ type: types.GET_PLANNING_DETAILS_COUNT, payload: count })
    }

    return count
  }
}

export function fetchPlanningDataCount(data) {
  return async function fetchPlanningThunk(dispatch) {
    const { isSuccess, result } = await safeFetchJson(
      buildGetUrl('/new_api/inventories/templates/planning/count', data),
    )

    let count = 0
    if (!isSuccess) {
      dispatch({ type: types.GET_PLANNING_COUNT, error: result })
    } else {
      count = +result[0].count || 0
      dispatch({ type: types.GET_PLANNING_COUNT, payload: count })
    }

    return count
  }
}

export async function createManufacturingOrder(data) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    const result = await (await safeFetch('/new_api/items/', requestOptions)).json()
    const payload = result.isSuccess ? result.result : null
    const error = !result.isSuccess ? result.result : null
    return { isSuccess: result.isSuccess, payload, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export async function fetchPlanningAnalysis(templateIds, data) {
  let analysis = []

  try {
    const result = await safeFetchJson(buildGetUrl(
      `/new_api/inventories/templates/${templateIds}/planning/analysis`,
      data,
    ))
    if (result.isSuccess) {
      analysis = result.result
    }
  } catch (err) {
    console.error(err)
  }

  return analysis
}

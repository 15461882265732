import { createSlice } from '@reduxjs/toolkit'

import { prefixItem } from 'components/alix-front/smart-grid/columns/utils'

import { buildGetUrl } from 'utils/api'
import { filterObjectKeys } from 'utils/mapperHelper'
import { safeFetchJson } from 'utils/safeFetch'

import {
  getFields as getSalesOrderFields,
  lineItemFields,
  defaultSalesOrderMapData,
  parseSalesOrder,
  parseSalesOrderLineItem,
} from './shared'
import { UPDATE_SALES_ORDER_ITEMS, UPDATE_ACTIVE_SALES_ORDER_STATUS } from './types/actions'

const initialState = {
  data: [],
  count: 0,
  fields: lineItemFields,
}

export const _prefixSalesOrderItem = '$item$'

const prefixSalesOrderItem = (item) => prefixItem(item, _prefixSalesOrderItem)

export function getFieldsJoinedToSalesOrder() {
  const soFields = getSalesOrderFields()

  const merged = {
    ...soFields,
    ...prefixSalesOrderItem(initialState.fields),
    id: initialState.fields.id,
    salesOrderId: soFields.id,
  }

  return merged
}

function parseJoinedToSalesOrderItem(salesOrder = {}, mapData = {}) {
  const isPrimaryLanguage = mapData.primaryLanguage === salesOrder.customer_language
  const documentLanguage = salesOrder.config?.language || salesOrder.customer_language
  const isDocumentPrimaryLanguage = mapData.primaryLanguage === documentLanguage

  const item = filterObjectKeys(salesOrder, 'salesorderitemview_')

  return parseSalesOrderLineItem(
    item, {
      defaultUnits: mapData.defaultUnits,
      isPrimaryLanguage,
      isDocumentPrimaryLanguage,
      taxDict: mapData.taxDict,
    },
  )
}

export function parseJoinedToSalesOrder(salesOrder = {}, mapData = {}, parsedSalesOrder, parsedItem) {
  parsedSalesOrder ||= parseSalesOrder(salesOrder, mapData)
  parsedItem ||= parseJoinedToSalesOrderItem(salesOrder, mapData)

  const merged = {
    ...parsedSalesOrder,
    ...prefixSalesOrderItem(parsedItem),
    id: parsedItem.id,
    salesOrderId: parsedSalesOrder.id,
  }

  return merged
}

const salesOrderItemsSlice = createSlice({
  name: 'salesOrderItems',
  initialState,
  reducers: {
    setSalesOrderItems: (state, action) => {
      state.data = action.payload.data
    },
    setSalesOrderItemsCount: (state, action) => {
      state.count = action.payload.count
    },
  },
})

export const { setSalesOrderItems, setSalesOrderItemsCount } = salesOrderItemsSlice.actions

export async function fetchSalesOrderItemByIds(ids, data = {}, mapData = {}) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/sales-orders/items/${ids.join(',')}`, { ...data, includeTaxDetails: true }),
  )

  return isSuccess ? result.map((lineItem) => parseSalesOrderLineItem(lineItem, mapData)) : []
}

const _getDefaultFetchMapData = () => ({
  joinToSalesOrderItems: true,
  excludeItems: true,
  includeTaxDetails: true,
  ...defaultSalesOrderMapData,
})

export function fetchSalesOrderItemsJoined(data = {}, mapData = {}) {
  return async function(dispatch) {
    const salesOrderItemsJoined = await _fetchSalesOrderItemsJoined(data, mapData)
    const formattedData = salesOrderItemsJoined.map((salesOrderItemJoined) => salesOrderItemJoined.joined)
    dispatch(setSalesOrderItems({ data: formattedData }))
    return formattedData
  }
}

export function fetchSalesOrderItems(data = {}, mapData = {}) {
  return async function(dispatch) {
    const salesOrdersItemJoined = await _fetchSalesOrderItemsJoined(data, mapData)
    const formattedData = salesOrdersItemJoined.map((salesOrderItemJoined) => salesOrderItemJoined.joined)
    const salesOrders = salesOrdersItemJoined.map((salesOrderItemJoined) => salesOrderItemJoined.salesOrder)
    const salesOrderItems = salesOrdersItemJoined.map((salesOrderItemJoined) => salesOrderItemJoined.salesOrderItem)
    dispatch({ type: UPDATE_ACTIVE_SALES_ORDER_STATUS, payload: salesOrders })
    dispatch({ type: UPDATE_SALES_ORDER_ITEMS, payload: { salesOrderItems, keepUserChanges: true } })
    return formattedData
  }
}

async function _fetchSalesOrderItemsJoined(data = {}, mapData) {
  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(
      `/new_api/sales-orders`,
      {
        ...data,
        ..._getDefaultFetchMapData(),
      },
    ),
  )

  const formattedData = isSuccess ? result.map((lineItem) => {
    const parsedSalesOrder = parseSalesOrder(lineItem, mapData)
    const parsedItem = parseJoinedToSalesOrderItem(lineItem, mapData)
    return {
      salesOrder: parsedSalesOrder,
      salesOrderItem: parsedItem,
      joined: parseJoinedToSalesOrder(lineItem, mapData, parsedSalesOrder, parsedItem),
    }
  }) : []

  return formattedData
}

const getCount = (result) => {
  result = Array.isArray(result) ? result[0] : result

  return Number(result?.count) || 0
}

export function _fetchSalesOrderItemCount(data = {}) {
  return async function(dispatch) {
    const { isSuccess, result } = await safeFetchJson(
      buildGetUrl(
        `/new_api/sales-orders/count`,
        {
          ...data,
          ..._getDefaultFetchMapData(),
        },
      ),
    )

    const count = isSuccess ? getCount(result) : 0

    dispatch(setSalesOrderItemsCount({ count }))

    return count
  }
}

export default salesOrderItemsSlice.reducer

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import SmartGridInlineColumn from 'components/alix-front/legacy-smart-grid/SmartGridInlineColumn'

import { parseCurrency, parsePercentage, roundNumber } from 'utils/numberParser'
import { convertFromBase, convertFromDollarPerBase } from 'utils/unitConverter'

import { parseTotalPrice } from 'reducers/sales-orders/shared'

import DiscountedInput from './DiscountedInput'
import DiscountInput from './DiscountInput'
import TaxDropdown from './TaxDropdown'
import UnitPriceInput from './UnitPriceInput'

export const getSalesOrderItemTotalPrice = (
  {
    quantity, discount, unitPrice, plannedUnitCost, dimension, unit,
  },
  {
    exchangeRate,
    currency,
    culture,
    priceMaxDigits,
    measureMaxDigits,
  },
) => {
  const totalPrice = parseTotalPrice(
    unitPrice || 0,
    dimension,
    unit,
    +quantity || 0,
    discount,
    {
      priceMaxDigits,
      measureMaxDigits,
    },
  )

  const quantityConverted = convertFromBase(dimension, +quantity || 0, unit, true)
  const unitCostConverted = convertFromDollarPerBase(dimension, plannedUnitCost, unit)
  const totalCost = +roundNumber((quantityConverted * unitCostConverted), 2)
  exchangeRate = +exchangeRate || 1
  const margin = !totalPrice ? 0 : (totalPrice - (totalCost / exchangeRate)) / totalPrice
  return `${parseCurrency(
    totalPrice,
    currency.currencyCode,
    currency.currencySymbol,
    2,
    culture,
  )} (${parsePercentage(margin, 1)})`
}

function DataHandlerUnitPriceTemplate({ salesOrderLineItem, editMode, costPopupRef }) {
  const { t } = useTranslation(['salesOrders'])

  const salesOrdersStore = useSelector((state) => state.salesOrders)
  const loginUser = useSelector((state) => state.loginUser)
  const settingsStore = useSelector((state) => state.settings)
  const priceList = useSelector((state) => state.priceLists.activePriceList)
  const lineItemCost = useSelector((state) => state.cost.itemCost)

  const [isClickable, setIsClickable] = useState(false)
  const [isPriceSynced, setIsPriceSynced] = useState(true)

  const culture = useMemo(() => `${loginUser.language || 'fr'}-CA`, [loginUser.language])
  const formData = useMemo(() => (
    salesOrdersStore.activeForm.lineItems.insertions[salesOrderLineItem.id] ||
    salesOrdersStore.activeForm.lineItems.updates[salesOrderLineItem.id] ||
    {}
  ), [
    // TODO (bzoretic) / TODO (lleduc): Would be nice to have insertions and updates as
    // dependencies instead of lineItems. Only lineItems get triggered at the moment.
    // More details on todo on UPDATE_SALES_ORDER_ITEMS.
    salesOrdersStore.activeForm.lineItems,
    salesOrderLineItem.id,
  ])

  useEffect(() => {
    setIsClickable(
      !editMode &&
      settingsStore.global.allowed_financial_groups?.value?.includes?.(loginUser.groupname) &&
      !!salesOrderLineItem.manufacturingOrderId &&
      salesOrderLineItem.templateSalesPriceComputeMode !== 'fixed_price',
    )
  }, [
    editMode,
    loginUser.groupname,
    salesOrderLineItem.manufacturingOrderId,
    settingsStore.global.allowed_financial_groups?.value,
    salesOrderLineItem.templateSalesPriceComputeMode,
  ])

  const onUniCostClick = useCallback(() => {
    if (isClickable) {
      return costPopupRef.current.open(salesOrderLineItem)
    }
  }, [costPopupRef, isClickable, salesOrderLineItem])

  const unitPriceInputTemplate = useCallback((lineItem) => (
    <UnitPriceInput
      salesOrderLineItem={lineItem}
      formData={formData}
      editMode={editMode}
      isClickable={isClickable}
      onValueClick={onUniCostClick}
      priceSynced={isPriceSynced}
    />
  ), [editMode, formData, isClickable, onUniCostClick, isPriceSynced])

  const discountInputTemplate = useCallback((lineItem) => (
    <DiscountInput
      salesOrderLineItem={lineItem}
      formData={formData}
      editMode={editMode}
    />
  ), [editMode, formData])

  const discountedInputTemplate = useCallback((lineItem) => (
    <DiscountedInput
      salesOrderLineItem={lineItem}
      formData={formData}
      editMode={editMode}
    />
  ), [editMode, formData])

  const taxDropdownTemplate = useCallback((lineItem) => (
    <TaxDropdown
      salesOrderLineItem={lineItem}
      editMode={editMode}
    />
  ), [editMode])

  useEffect(() => {
    const insertion = salesOrdersStore.activeForm.lineItems.insertions[salesOrderLineItem.id]
    const sellingPrice = salesOrderLineItem.unitPrice || 0
    setIsPriceSynced(true)
    if (salesOrderLineItem.templateId) {
      const priceListLineItem = priceList?.lineItems?.find(
        (item) => item.templateId === salesOrderLineItem.templateId,
      )
      if (priceListLineItem && priceListLineItem?.rate !== sellingPrice && !insertion) setIsPriceSynced(false)
      if (!priceListLineItem && sellingPrice !== (salesOrderLineItem.templateUnitSellingPrice || 0) && !insertion) {
        setIsPriceSynced(false)
      }
    } else {
      const cost = (lineItemCost?.find(
        (item) => item.manufacturingOrderId === salesOrderLineItem.manufacturingOrderId)
      )?.plannedPrice?.total?.resultUnitary

      if (cost && cost !== sellingPrice) setIsPriceSynced(false)
    }
  }, [salesOrderLineItem, priceList, lineItemCost, salesOrdersStore.activeForm.lineItems])

  const unitPriceTemplateClasses = useMemo(() => {
    const classes = ['a-inline-label-border', 'a-measure-clickable-link-label']
    if (!isPriceSynced) {
      classes.push('a-smart-status-label-yellow')
    }
    return classes.join(' ')
  }, [isPriceSynced])

  const parseTotalPricePercentage = useCallback((item) => {
    return getSalesOrderItemTotalPrice(
      {
        quantity: formData.quantity?.value,
        discount: formData.discount?.value,
        unitPrice: formData.unitPrice?.value,
        plannedUnitCost: item.plannedUnitCost,
        dimension: item.dimension,
        unit: item.unit,
      },
      {
        exchangeRate: salesOrdersStore.activeSalesOrder.exchangeRate,
        currency: salesOrdersStore.activeForm.currencyInfo,
        culture,
        priceMaxDigits: settingsStore.global.number_decimals.price_max,
        measureMaxDigits: settingsStore.global.number_decimals.measure_max,
      },
    )
  }, [
    culture,
    formData.quantity?.value,
    formData.discount?.value,
    formData.unitPrice?.value,
    salesOrdersStore.activeForm.currencyInfo,
    salesOrdersStore.activeSalesOrder.exchangeRate,
    settingsStore,
  ])

  if (salesOrderLineItem.isGroup || salesOrderLineItem.isLazyRender) {
    return null
  }

  return (
    <SmartGridInlineColumn
      item={salesOrderLineItem}
      inlineData={[
        {
          label: t('salesOrders:salesOrder.item.unitPrice'),
          field: 'unitPrice',
          labelClassName: unitPriceTemplateClasses,
          inputTemplate: unitPriceInputTemplate,
        },
        {
          label: t('salesOrders:salesOrder.item.discount'),
          field: 'discount',
          labelClassName: 'a-inline-label-border',
          inputTemplate: discountInputTemplate,
        },
        {
          label: t('salesOrders:salesOrder.item.discounted'),
          field: 'discounted',
          labelClassName: 'a-inline-label-border',
          inputTemplate: discountedInputTemplate,
        },
        {
          label: t('salesOrders:salesOrder.item.total'),
          field: 'totalPrice',
          parse: parseTotalPricePercentage,
        },
        {
          label: t('salesOrders:salesOrder.item.tax'),
          field: 'taxId',
          inputTemplate: taxDropdownTemplate,
        },
      ]}
    />
  )
}

export default DataHandlerUnitPriceTemplate

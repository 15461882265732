import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  getPrefixedComplexColumns as getPrefixedInventoryComplexColumns,
} from 'components/alix-front/legacy-smart-grid/columns/inventories'
import {
  getPrefixedComplexColumns as getPrefixedReceptionComplexColumns,
} from 'components/alix-front/legacy-smart-grid/columns/receptions'
import { SmartEntityLink } from 'components/alix-front/smart-entity-link/SmartEntityLink'
import { ColumnDict, sortColumnDictFromKeys } from 'components/alix-front/smart-grid/columns/utils'

import { getDimensionIcon } from 'utils/iconMapper'
import { parseNumber } from 'utils/numberParser'

import { Inventory } from 'reducers/inventories/inventoriesSlice'
import {
  FullReceptionItem,
  inventoryFieldPrefix,
  Reception,
  receptionFieldPrefix,
  ReceptionItem,
} from 'reducers/receptions/receptionType'
import { getLineItemFields } from 'reducers/receptions/shared'

const defaultOptions = {
  sortable: true,
  filter: true,
  isGlobalSearch: true,
}

const forceColumnOptions = {
  isColumnToggle: true,
}

const forceIsViewHiddenFields = Object.keys(getLineItemFields())

const {
  columnDict: inventoryColumnDict,
  getComplexColumns: getInventoryComplexColumns,
  prefixField: inventoryPrefixField,
} = getPrefixedInventoryComplexColumns<Inventory>({
  prefix: inventoryFieldPrefix,
  forceColumnOptions,
  prefixHeaders: true,
  isCamelCase: true,
  excludeAllOtherfields: forceIsViewHiddenFields,
})

const {
  columnDict: receptionColumnDict,
  getComplexColumns: getReceptionComplexColumns,
} = getPrefixedReceptionComplexColumns<Reception>({
  prefix: receptionFieldPrefix,
  forceColumnOptions,
  prefixHeaders: true,
  isCamelCase: true,
  excludeAllOtherfields: forceIsViewHiddenFields,
})

const _invisibleOptions = { hidden: true, filter: true, sortable: true, isGlobalSearch: true }
const receptionItemColumnDict: ColumnDict<ReceptionItem> = {
  vendorDisplayName: { field: 'vendorDisplayName', ...defaultOptions },
  dimension: {
    field: 'dimension',
    filter: true,
    sortable: true,
    filterElement: { type: 'multiselect' },
  },
  locationTitle: { field: 'locationTitle', ..._invisibleOptions },
  templateSku: { field: 'templateSku', ...defaultOptions },
  projectTitle: { field: 'projectTitle', ..._invisibleOptions },
  createdDate: { field: 'createdDate', hidden: true, sortable: true, filter: true },
  modifiedDate: { field: 'modifiedDate', hidden: true, sortable: true, filter: true },
  convertedMeasure: { field: 'convertedMeasure', sortable: true },
  groupName: { field: 'groupName', ...defaultOptions, hidden: true },
  materialTitle: { field: 'materialTitle', ...defaultOptions, hidden: true },
  categoryTitle: { field: 'categoryTitle', ...defaultOptions, hidden: true },
  rawImperialTitle: { field: 'rawImperialTitle', ...defaultOptions, hidden: true },
  rawMetricTitle: { field: 'rawMetricTitle', ...defaultOptions, hidden: true },
  treatmentTitle: { field: 'treatmentTitle', ...defaultOptions, hidden: true },
}

export const columnDict = sortColumnDictFromKeys({
  ...receptionItemColumnDict,
  ...receptionColumnDict,
  ...inventoryColumnDict,
}, [
  'receptionName',
  'receptionInvoiceReference',
  'receptionStatus',
  'receptionCarrier',
  'receptionDriver',
  'receptionFormattedReceiptDate',
  'receptionPlantName',

  'dimension',
  'templateSku',
  'locationTitle',
  'convertedMeasure',
  'modifiedDate',
  'projectTitle',
  'groupName',
  'materialTitle',
  'categoryTitle',
  'rawImperialTitle',
  'rawMetricTitle',
  'treatmentTitle',

  'vendorDisplayName',
  'createdDate',

  'inventoryTag',
  'inventoryPartNumber',
  'inventoryVendorPartNumber',
  'inventoryInvoice',
  'inventoryInvoiceDate',
  'inventorySecondaryDescription',
  'inventoryDescription',
  'inventoryManufacturer',
  'inventorySerialNumber',
  'inventoryRevision',
  'inventoryStatus',
])

const addDefaultProps = (key: keyof typeof receptionItemColumnDict) => inventoryColumnDict[key]

const _measure = (value, unit, { measureDigits, culture }) => {
  const parsedNumber = parseNumber(value, measureDigits, culture)

  return `${parsedNumber} ${unit}`
}

export function getComplexColumns(t, options) {
  const inventoryComplexColumn = getInventoryComplexColumns(t, options)
  const receptionComplexColumn = getReceptionComplexColumns(t, options)

  const complexeColumns: ColumnDict<FullReceptionItem> = {
    ...receptionComplexColumn,
    ...inventoryComplexColumn,

    vendorDisplayName: {
      ...addDefaultProps('vendorDisplayName'),
      header: t('receptions:reception.fields.vendorDisplayName.label'),
    },
    convertedMeasure: {
      ...addDefaultProps('convertedMeasure'),
      header: t('receptions:reception.fields.convertedMeasure'),
      parseValue: (props) => _measure(
        props.convertedMeasure,
        props.measureUnit,
        { measureDigits: options.measureDigits, culture: options.culture },
      ),
    },
    dimension: {
      header: t('items:item.fields.item'),
      filterElement: {
        ...options.simpleDict.dimension.filterElement,
        options: [
          { label: t('common:dimension.qty'), value: 'qty' },
          { label: t('common:dimension.weight'), value: 'weight' },
          { label: t('common:dimension.length'), value: 'length' },
          { label: t('common:dimension.surface'), value: 'surface' },
          { label: t('common:dimension.volume'), value: 'volume' },
        ],
        itemTemplate: (option) => {
          const iconData = getDimensionIcon(option.value)
          return (
            <div className="a-flex">
              <div className="a-item-grid-dimension-icon">
                <FontAwesomeIcon
                  icon={['fas', iconData.icon]}
                  transform={iconData.transform}
                />
              </div>
              <div>
                {option.label}
              </div>
            </div>
          )
        },
      },
      template: (props) => {
        const iconData = getDimensionIcon(props.dimension)
        return (
          <div className="a-flex">
            <div className="a-item-grid-dimension-icon">
              <FontAwesomeIcon
                icon={['fas', iconData.icon]}
                transform={iconData.transform}
              />
            </div>
            <SmartEntityLink
              id={props.templateId}
              entityName="items"
            >
              {props.templateTitle}
            </SmartEntityLink>
          </div>
        )
      },
    },
    locationTitle: {
      ...addDefaultProps('locationTitle'),
      header: t('locations:location.fields.location'),
    },
    templateSku: {
      ...addDefaultProps(`templateSku`),
      header: t('items:item.fields.sku.label'),
    },
    projectTitle: {
      ...addDefaultProps('projectTitle'),
      header: t('inventories:inventory.fields.project'),
    },
    createdDate: {
      ...addDefaultProps('createdDate'),
      header: t('inventories:inventory.fields.createdDate'),
      template: (props) => options.dateColumnTemplate(props.createdDate, false),
    },
    modifiedDate: {
      ...addDefaultProps('modifiedDate'),
      header: t('inventories:inventory.fields.modifiedDate'),
      template: (props) => options.dateColumnTemplate(props.modifiedDate, false),
    },
    [inventoryPrefixField('tag')]: {
      ...inventoryComplexColumn[inventoryPrefixField('tag')],
      template: (props) => {
        return (
          <SmartEntityLink
            id={props[inventoryPrefixField('id')]}
            entityName="inventories"
          >
            {props[inventoryPrefixField('tag')]}
          </SmartEntityLink>
        )
      },
    },

    groupName: {
      ...addDefaultProps('groupName'),
      header: t('inventories:inventory.fields.group'),
    },
    materialTitle: {
      ...addDefaultProps('materialTitle'),
      header: t('inventories:inventory.fields.material'),
    },
    categoryTitle: {
      ...addDefaultProps('categoryTitle'),
      header: t('inventories:inventory.fields.category'),
    },
    rawImperialTitle: {
      ...addDefaultProps('rawImperialTitle'),
      header: t('inventories:inventory.fields.rawImperial'),
    },
    rawMetricTitle: {
      ...addDefaultProps('rawMetricTitle'),
      header: t('inventories:inventory.fields.rawMetric'),
    },
    treatmentTitle: {
      ...addDefaultProps('treatmentTitle'),
      header: t('inventories:inventory.fields.treatment'),
    },
  }

  return complexeColumns
}

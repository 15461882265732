import { mapColumns } from 'components/alix-front/smart-grid/columns/utils'

import { isNullOrEmpty } from 'utils/defaultValueHelper'
import { entities } from 'utils/entities'
import { useDataHandlerParams } from 'utils/useSimpleDataHandler'

import { _fetchTaxes } from 'reducers/taxes/taxesSlice'

export const columnDict = {
  displayName: {
    field: 'displayName',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    isDefaultSort: true,
    isColumnToggle: false,
    fieldInfo: { dataSetName: 'contactView', dbField: 'display_name', isEdit: true, type: 'string' },
  },
  companyName: { field: 'companyName',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    dataSetName: 'contactView',
    dbField: 'company_name',
    isEdit: true,
    type: 'string',
  },
  status: {
    field: 'status',
    sortable: true,
    filter: true,
    filterElement: { type: 'multiselect' },
    isColumnToggle: false,
    dataSetName: 'contactView',
    dbField: 'status',
    defaultValue: 'active',
    isEdit: true,
  },
  code: {
    field: 'code',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    dataSetName: 'contactView',
    dbField: 'code',
    isEdit: true,
    type: 'string',
  },
  isCarrier: { field: 'isCarrier',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
    dataSetName: 'contactView',
    dbField: 'is_carrier',
    isEdit: true,
    type: 'boolean',
  },
  category: { field: 'category',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
    dataSetName: 'contactView',
    dbField: 'category_name',
    isEdit: false,
    type: 'string',
  },
  language: { field: 'language',
    sortable: true,
    filter: true,
    filterElement: { type: 'multiselect' },
    hidden: true,
    dataSetName: 'contactView',
    dbField: 'language',
    isEdit: true,
    type: 'language',
  },
  website: { field: 'website',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
    dataSetName: 'contactView',
    dbField: 'website',
    isEdit: true,
    type: 'string',
  },
  currencyCode: { field: 'currencyCode',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    dataSetName: 'contactView',
    dbField: 'currency_code',
    isEdit: false,
    type: 'string',
  },
  zohoBooksContactId: { field: 'zohoBooksContactId', sortable: true, filter: true, isGlobalSearch: true, hidden: true },
  markup: { field: 'markup', sortable: true, hidden: true },
  paymentTerms: {
    field: 'paymentTerms',
    sortable: true,
    mapColumnsTemplate: (value, _, t) => {
      return isNullOrEmpty(value) ?
        t('contacts:contact.fields.paymentTerms.fallback') :
        `${value} ${t('common:time.day', { count: value })}`
    },
    hidden: true,
    dataSetName: 'contactView',
    dbField: 'payment_terms',
    isEdit: true,
  },
  pricelistName: {
    field: 'pricelistName',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    translationKey: 'priceList',
    hidden: true,
    dataSetName: 'contactView',
    dbField: 'price_list_name',
    isEdit: false,
    type: 'string',
  },
  relatedContactDisplayName: {
    field: 'relatedContactDisplayName',
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    dataSetName: 'contactView',
    dbField: 'related_contact_display_name',
    isEdit: false,
    type: 'string',
  },
  createdDate: { field: 'createdDate', isColumnToggle: true, sortable: true, filter: true, hidden: true },
  createdBy: {
    field: 'createdBy',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  modifiedDate: { field: 'modifiedDate', isColumnToggle: true, sortable: true, filter: true, hidden: true },
  modifiedBy: {
    field: 'modifiedBy',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },

  mainPersonSalutation: {
    field: 'mainPersonSalutation',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainPersonFirstName: {
    field: 'mainPersonFirstName',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainPersonLastName: {
    field: 'mainPersonLastName',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainPersonFullName: {
    field: 'mainPersonFullName',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainPersonEmail: {
    field: 'mainPersonEmail',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainPersonPhone: {
    field: 'mainPersonPhone',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainPersonMobile: {
    field: 'mainPersonMobile',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainPersonDesignation: {
    field: 'mainPersonDesignation',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainPersonDepartment: {
    field: 'mainPersonDepartment',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainPersonSkype: {
    field: 'mainPersonSkype',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },

  mainBillingAttention: {
    field: 'mainBillingAttention',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainBillingAddress: {
    field: 'mainBillingAddress',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainBillingStreet2: {
    field: 'mainBillingStreet2',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainBillingStateCode: {
    field: 'mainBillingStateCode',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainBillingCity: {
    field: 'mainBillingCity',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainBillingState: {
    field: 'mainBillingState',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainBillingZip: {
    field: 'mainBillingZip',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainBillingCountry: {
    field: 'mainBillingCountry',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainBillingFax: {
    field: 'mainBillingFax',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainBillingPhone: {
    field: 'mainBillingPhone',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },

  mainShippingAttention: {
    field: 'mainShippingAttention',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainShippingAddress: {
    field: 'mainShippingAddress',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainShippingStreet2: {
    field: 'mainShippingStreet2',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainShippingStateCode: {
    field: 'mainShippingStateCode',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainShippingCity: {
    field: 'mainShippingCity',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainShippingState: {
    field: 'mainShippingState',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainShippingZip: {
    field: 'mainShippingZip',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainShippingCountry: {
    field: 'mainShippingCountry',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainShippingFax: {
    field: 'mainShippingFax',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  mainShippingPhone: {
    field: 'mainShippingPhone',
    isColumnToggle: true,
    sortable: true,
    filter: true,
    isGlobalSearch: true,
    hidden: true,
  },
  taxId: {
    field: 'taxId',
    sortable: false,
    filter: true,
    hidden: true,
  },
  taxRuleId: {
    field: 'taxRuleId',
    sortable: false,
    filter: true,
    hidden: true,
  },
}

/**
 * @type {NonNullable<useDataHandlerParams["entityFetchesForParse"]>}
 */
export const entityFetchesForParse = [
  { entity: 'taxes', fetcher: _fetchTaxes },
]

export function getComplexColumns(t, { fields, measureDigits, culture, simpleDict }) {
  const complexColumns = mapColumns(
    Object.values({
      ...simpleDict,
      paymentTerms: {
        ...simpleDict.paymentTerms,
        template: (props) => {
          return isNullOrEmpty(props.paymentTerms) ?
            t('contacts:contact.fields.paymentTerms.fallback') :
            `${props.paymentTerms} ${t('common:time.day', { count: props.paymentTerms })}`
        },
      },
      taxId: {
        ...simpleDict.taxId,
        template: (props) => props.tax?.displayName ?? t('contacts:contact.fields.tax.fallback'),
      },
      taxRuleId: {
        ...simpleDict.taxRuleId,
        template: (props) => props.taxRule?.taxRuleName ?? t('contacts:contact.fields.taxRule.fallback'),
      },
    }),
    { t, fields, entity: entities['contacts'], handlerOptions: {
      measureDigits,
      culture,
      useEntityTranslation: true,
    } },
  )

  return complexColumns.reduce((acc, column) => {
    acc[column.field] = column
    return acc
  }, {})
}
